/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `workbook` - Workbook * `problem-board` - Problem board * `digital-textbook` - Digital textbook
 * @export
 * @enum {string}
 */

export const CssTypeEnum = {
    Workbook: 'workbook',
    ProblemBoard: 'problem-board',
    DigitalTextbook: 'digital-textbook'
} as const;

export type CssTypeEnum = typeof CssTypeEnum[keyof typeof CssTypeEnum];



