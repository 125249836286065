/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `unprocessed` - Unprocessed * `imported` - Imported * `rejected` - Rejected
 * @export
 * @enum {string}
 */

export const ProblemsImportCandidateStatusEnum = {
    Unprocessed: 'unprocessed',
    Imported: 'imported',
    Rejected: 'rejected'
} as const;

export type ProblemsImportCandidateStatusEnum = typeof ProblemsImportCandidateStatusEnum[keyof typeof ProblemsImportCandidateStatusEnum];



