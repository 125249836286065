/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `layout_1` - Layout 1 * `layout_2` - Layout 2 * `layout_3` - Layout 3 * `layout_4` - Layout 4 * `layout_5` - Layout 5
 * @export
 * @enum {string}
 */

export const BoardLayoutEnum = {
    _1: 'layout_1',
    _2: 'layout_2',
    _3: 'layout_3',
    _4: 'layout_4',
    _5: 'layout_5'
} as const;

export type BoardLayoutEnum = typeof BoardLayoutEnum[keyof typeof BoardLayoutEnum];



