/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `single_choice` - Single choice * `multiple_choice` - Multiple choice * `simple_text` - Simple text * `fill_in_the_blank` - Fill in the blank * `autocomplete` - Autocomplete * `autocomplete_from_same_pool` - Autocomplete from same pool * `match_the_answers` - Match the answers * `fill_in_the_table` - Fill in the table
 * @export
 * @enum {string}
 */

export const QuestionTypeEnum = {
    SingleChoice: 'single_choice',
    MultipleChoice: 'multiple_choice',
    SimpleText: 'simple_text',
    FillInTheBlank: 'fill_in_the_blank',
    Autocomplete: 'autocomplete',
    AutocompleteFromSamePool: 'autocomplete_from_same_pool',
    MatchTheAnswers: 'match_the_answers',
    FillInTheTable: 'fill_in_the_table'
} as const;

export type QuestionTypeEnum = typeof QuestionTypeEnum[keyof typeof QuestionTypeEnum];



