/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `with_video` - With video * `quiz_only` - Quiz only * `essay` - Essay
 * @export
 * @enum {string}
 */

export const TypeB23Enum = {
    WithVideo: 'with_video',
    QuizOnly: 'quiz_only',
    Essay: 'essay'
} as const;

export type TypeB23Enum = typeof TypeB23Enum[keyof typeof TypeB23Enum];



