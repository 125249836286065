/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `/auth/login` - /auth/login * `/auth/register` - /auth/register * `/auth/password/reset` - /auth/password/reset * `/auth/password/change` - /auth/password/change * `/frontoffice` - /frontoffice * `/blogs` - /blogs
 * @export
 * @enum {string}
 */

export const PredifinedLinkEnum = {
    AuthLogin: '/auth/login',
    AuthRegister: '/auth/register',
    AuthPasswordReset: '/auth/password/reset',
    AuthPasswordChange: '/auth/password/change',
    Frontoffice: '/frontoffice',
    Blogs: '/blogs'
} as const;

export type PredifinedLinkEnum = typeof PredifinedLinkEnum[keyof typeof PredifinedLinkEnum];



