/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `unprocessed` - Unprocessed * `ready` - Ready * `rejected` - Rejected * `processed` - Processed
 * @export
 * @enum {string}
 */

export const ProblemsImportImageStatusEnum = {
    Unprocessed: 'unprocessed',
    Ready: 'ready',
    Rejected: 'rejected',
    Processed: 'processed'
} as const;

export type ProblemsImportImageStatusEnum = typeof ProblemsImportImageStatusEnum[keyof typeof ProblemsImportImageStatusEnum];



