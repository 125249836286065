/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CustomRegisterRequest } from '../models';
// @ts-ignore
import type { CustomUserDetails } from '../models';
// @ts-ignore
import type { CustomUserDetailsRequest } from '../models';
// @ts-ignore
import type { IsUserWithEmailActiveRequest } from '../models';
// @ts-ignore
import type { IsUsernameAvailableRequest } from '../models';
// @ts-ignore
import type { IsUsernameAvailableResponse } from '../models';
// @ts-ignore
import type { JWT } from '../models';
// @ts-ignore
import type { LoginRequest } from '../models';
// @ts-ignore
import type { PaginatedSocialAccountList } from '../models';
// @ts-ignore
import type { PasswordChangeRequest } from '../models';
// @ts-ignore
import type { PasswordResetConfirmRequest } from '../models';
// @ts-ignore
import type { PasswordResetRequest } from '../models';
// @ts-ignore
import type { PatchedCustomUserDetailsRequest } from '../models';
// @ts-ignore
import type { ResendEmailVerificationRequest } from '../models';
// @ts-ignore
import type { RestAuthDetail } from '../models';
// @ts-ignore
import type { SocialConnect } from '../models';
// @ts-ignore
import type { SocialConnectRequest } from '../models';
// @ts-ignore
import type { SocialLogin } from '../models';
// @ts-ignore
import type { SocialLoginRequest } from '../models';
// @ts-ignore
import type { TokenRefresh } from '../models';
// @ts-ignore
import type { TokenRefreshRequest } from '../models';
// @ts-ignore
import type { TokenVerifyRequest } from '../models';
// @ts-ignore
import type { VerifyEmailRequest } from '../models';
/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * class used for social account linking  example usage for facebook with access_token ------------- from allauth.socialaccount.providers.facebook.views import FacebookOAuth2Adapter  class FacebookConnect(SocialConnectView):     adapter_class = FacebookOAuth2Adapter -------------
         * @param {SocialConnectRequest} [socialConnectRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiGoogleConnectCreate: async (socialConnectRequest?: SocialConnectRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/auth/api/google/connect/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(socialConnectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * class used for social authentications example usage for facebook with access_token ------------- from allauth.socialaccount.providers.facebook.views import FacebookOAuth2Adapter  class FacebookLogin(SocialLoginView):     adapter_class = FacebookOAuth2Adapter -------------  example usage for facebook with code  ------------- from allauth.socialaccount.providers.facebook.views import FacebookOAuth2Adapter from allauth.socialaccount.providers.oauth2.client import OAuth2Client  class FacebookLogin(SocialLoginView):     adapter_class = FacebookOAuth2Adapter     client_class = OAuth2Client     callback_url = \'localhost:8000\' -------------
         * @param {SocialLoginRequest} [socialLoginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiGoogleLoginCreate: async (socialLoginRequest?: SocialLoginRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/auth/api/google/login/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(socialLoginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IsUserWithEmailActiveRequest} isUserWithEmailActiveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiIsUserWithEmailActiveCreate: async (isUserWithEmailActiveRequest: IsUserWithEmailActiveRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'isUserWithEmailActiveRequest' is not null or undefined
            assertParamExists('authApiIsUserWithEmailActiveCreate', 'isUserWithEmailActiveRequest', isUserWithEmailActiveRequest)
            const localVarPath = `/wt/auth/api/is-user-with-email-active/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isUserWithEmailActiveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IsUsernameAvailableRequest} isUsernameAvailableRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiIsUsernameAvailableCreate: async (isUsernameAvailableRequest: IsUsernameAvailableRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'isUsernameAvailableRequest' is not null or undefined
            assertParamExists('authApiIsUsernameAvailableCreate', 'isUsernameAvailableRequest', isUsernameAvailableRequest)
            const localVarPath = `/wt/auth/api/is-username-available/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isUsernameAvailableRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Check the credentials and return the REST Token if the credentials are valid and authenticated. Calls Django Auth login method to register User ID in Django session framework  Accept the following POST parameters: username, password Return the REST Framework Token Object\'s key.
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiLoginCreate: async (loginRequest: LoginRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginRequest' is not null or undefined
            assertParamExists('authApiLoginCreate', 'loginRequest', loginRequest)
            const localVarPath = `/wt/auth/api/login/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Calls Django logout method and delete the Token object assigned to the current User object.  Accepts/Returns nothing.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiLogoutCreate: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/auth/api/logout/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Calls Django Auth SetPasswordForm save method.  Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
         * @param {PasswordChangeRequest} passwordChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiPasswordChangeCreate: async (passwordChangeRequest: PasswordChangeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordChangeRequest' is not null or undefined
            assertParamExists('authApiPasswordChangeCreate', 'passwordChangeRequest', passwordChangeRequest)
            const localVarPath = `/wt/auth/api/password/change/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordChangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Password reset e-mail link is confirmed, therefore this resets the user\'s password.  Accepts the following POST parameters: token, uid,     new_password1, new_password2 Returns the success/fail message.
         * @param {PasswordResetConfirmRequest} passwordResetConfirmRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiPasswordResetConfirmCreate: async (passwordResetConfirmRequest: PasswordResetConfirmRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordResetConfirmRequest' is not null or undefined
            assertParamExists('authApiPasswordResetConfirmCreate', 'passwordResetConfirmRequest', passwordResetConfirmRequest)
            const localVarPath = `/wt/auth/api/password/reset/confirm/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordResetConfirmRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Calls Django Auth PasswordResetForm save method.  Accepts the following POST parameters: email Returns the success/fail message.
         * @param {PasswordResetRequest} passwordResetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiPasswordResetCreate: async (passwordResetRequest: PasswordResetRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordResetRequest' is not null or undefined
            assertParamExists('authApiPasswordResetCreate', 'passwordResetRequest', passwordResetRequest)
            const localVarPath = `/wt/auth/api/password/reset/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordResetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CustomRegisterRequest} customRegisterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiRegisterCreate: async (customRegisterRequest: CustomRegisterRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customRegisterRequest' is not null or undefined
            assertParamExists('authApiRegisterCreate', 'customRegisterRequest', customRegisterRequest)
            const localVarPath = `/wt/auth/api/register/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customRegisterRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResendEmailVerificationRequest} resendEmailVerificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiResendEmailVerificationCreate: async (resendEmailVerificationRequest: ResendEmailVerificationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resendEmailVerificationRequest' is not null or undefined
            assertParamExists('authApiResendEmailVerificationCreate', 'resendEmailVerificationRequest', resendEmailVerificationRequest)
            const localVarPath = `/wt/auth/api/resend-email-verification/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resendEmailVerificationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List SocialAccounts for the currently logged in user
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiSocialAccountsList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/auth/api/social-accounts/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Disconnect SocialAccount from remote service for the currently logged in user
         * @param {SocialConnectRequest} [socialConnectRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiSocialDisconnectCreate: async (socialConnectRequest?: SocialConnectRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/auth/api/social-disconnect/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(socialConnectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
         * @param {TokenRefreshRequest} tokenRefreshRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiTokenRefreshCreate: async (tokenRefreshRequest: TokenRefreshRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tokenRefreshRequest' is not null or undefined
            assertParamExists('authApiTokenRefreshCreate', 'tokenRefreshRequest', tokenRefreshRequest)
            const localVarPath = `/wt/auth/api/token/refresh/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tokenRefreshRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Takes a token and indicates if it is valid.  This view provides no information about a token\'s fitness for a particular use.
         * @param {TokenVerifyRequest} tokenVerifyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiTokenVerifyCreate: async (tokenVerifyRequest: TokenVerifyRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tokenVerifyRequest' is not null or undefined
            assertParamExists('authApiTokenVerifyCreate', 'tokenVerifyRequest', tokenVerifyRequest)
            const localVarPath = `/wt/auth/api/token/verify/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tokenVerifyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
         * @param {PatchedCustomUserDetailsRequest} [patchedCustomUserDetailsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiUserPartialUpdate: async (patchedCustomUserDetailsRequest?: PatchedCustomUserDetailsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/auth/api/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedCustomUserDetailsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiUserRetrieve: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/auth/api/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
         * @param {CustomUserDetailsRequest} customUserDetailsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiUserUpdate: async (customUserDetailsRequest: CustomUserDetailsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customUserDetailsRequest' is not null or undefined
            assertParamExists('authApiUserUpdate', 'customUserDetailsRequest', customUserDetailsRequest)
            const localVarPath = `/wt/auth/api/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUserDetailsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyEmailRequest} verifyEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiVerifyEmailCreate: async (verifyEmailRequest: VerifyEmailRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifyEmailRequest' is not null or undefined
            assertParamExists('authApiVerifyEmailCreate', 'verifyEmailRequest', verifyEmailRequest)
            const localVarPath = `/wt/auth/api/verify-email/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * class used for social account linking  example usage for facebook with access_token ------------- from allauth.socialaccount.providers.facebook.views import FacebookOAuth2Adapter  class FacebookConnect(SocialConnectView):     adapter_class = FacebookOAuth2Adapter -------------
         * @param {SocialConnectRequest} [socialConnectRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authApiGoogleConnectCreate(socialConnectRequest?: SocialConnectRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SocialConnect>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authApiGoogleConnectCreate(socialConnectRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authApiGoogleConnectCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * class used for social authentications example usage for facebook with access_token ------------- from allauth.socialaccount.providers.facebook.views import FacebookOAuth2Adapter  class FacebookLogin(SocialLoginView):     adapter_class = FacebookOAuth2Adapter -------------  example usage for facebook with code  ------------- from allauth.socialaccount.providers.facebook.views import FacebookOAuth2Adapter from allauth.socialaccount.providers.oauth2.client import OAuth2Client  class FacebookLogin(SocialLoginView):     adapter_class = FacebookOAuth2Adapter     client_class = OAuth2Client     callback_url = \'localhost:8000\' -------------
         * @param {SocialLoginRequest} [socialLoginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authApiGoogleLoginCreate(socialLoginRequest?: SocialLoginRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SocialLogin>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authApiGoogleLoginCreate(socialLoginRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authApiGoogleLoginCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {IsUserWithEmailActiveRequest} isUserWithEmailActiveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authApiIsUserWithEmailActiveCreate(isUserWithEmailActiveRequest: IsUserWithEmailActiveRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authApiIsUserWithEmailActiveCreate(isUserWithEmailActiveRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authApiIsUserWithEmailActiveCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {IsUsernameAvailableRequest} isUsernameAvailableRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authApiIsUsernameAvailableCreate(isUsernameAvailableRequest: IsUsernameAvailableRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IsUsernameAvailableResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authApiIsUsernameAvailableCreate(isUsernameAvailableRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authApiIsUsernameAvailableCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Check the credentials and return the REST Token if the credentials are valid and authenticated. Calls Django Auth login method to register User ID in Django session framework  Accept the following POST parameters: username, password Return the REST Framework Token Object\'s key.
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authApiLoginCreate(loginRequest: LoginRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JWT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authApiLoginCreate(loginRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authApiLoginCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Calls Django logout method and delete the Token object assigned to the current User object.  Accepts/Returns nothing.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authApiLogoutCreate(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestAuthDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authApiLogoutCreate(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authApiLogoutCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Calls Django Auth SetPasswordForm save method.  Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
         * @param {PasswordChangeRequest} passwordChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authApiPasswordChangeCreate(passwordChangeRequest: PasswordChangeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestAuthDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authApiPasswordChangeCreate(passwordChangeRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authApiPasswordChangeCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Password reset e-mail link is confirmed, therefore this resets the user\'s password.  Accepts the following POST parameters: token, uid,     new_password1, new_password2 Returns the success/fail message.
         * @param {PasswordResetConfirmRequest} passwordResetConfirmRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authApiPasswordResetConfirmCreate(passwordResetConfirmRequest: PasswordResetConfirmRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestAuthDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authApiPasswordResetConfirmCreate(passwordResetConfirmRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authApiPasswordResetConfirmCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Calls Django Auth PasswordResetForm save method.  Accepts the following POST parameters: email Returns the success/fail message.
         * @param {PasswordResetRequest} passwordResetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authApiPasswordResetCreate(passwordResetRequest: PasswordResetRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestAuthDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authApiPasswordResetCreate(passwordResetRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authApiPasswordResetCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CustomRegisterRequest} customRegisterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authApiRegisterCreate(customRegisterRequest: CustomRegisterRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestAuthDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authApiRegisterCreate(customRegisterRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authApiRegisterCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ResendEmailVerificationRequest} resendEmailVerificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authApiResendEmailVerificationCreate(resendEmailVerificationRequest: ResendEmailVerificationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestAuthDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authApiResendEmailVerificationCreate(resendEmailVerificationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authApiResendEmailVerificationCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List SocialAccounts for the currently logged in user
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authApiSocialAccountsList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedSocialAccountList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authApiSocialAccountsList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authApiSocialAccountsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Disconnect SocialAccount from remote service for the currently logged in user
         * @param {SocialConnectRequest} [socialConnectRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authApiSocialDisconnectCreate(socialConnectRequest?: SocialConnectRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SocialConnect>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authApiSocialDisconnectCreate(socialConnectRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authApiSocialDisconnectCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
         * @param {TokenRefreshRequest} tokenRefreshRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authApiTokenRefreshCreate(tokenRefreshRequest: TokenRefreshRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenRefresh>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authApiTokenRefreshCreate(tokenRefreshRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authApiTokenRefreshCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Takes a token and indicates if it is valid.  This view provides no information about a token\'s fitness for a particular use.
         * @param {TokenVerifyRequest} tokenVerifyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authApiTokenVerifyCreate(tokenVerifyRequest: TokenVerifyRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authApiTokenVerifyCreate(tokenVerifyRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authApiTokenVerifyCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
         * @param {PatchedCustomUserDetailsRequest} [patchedCustomUserDetailsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authApiUserPartialUpdate(patchedCustomUserDetailsRequest?: PatchedCustomUserDetailsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomUserDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authApiUserPartialUpdate(patchedCustomUserDetailsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authApiUserPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authApiUserRetrieve(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomUserDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authApiUserRetrieve(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authApiUserRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
         * @param {CustomUserDetailsRequest} customUserDetailsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authApiUserUpdate(customUserDetailsRequest: CustomUserDetailsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomUserDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authApiUserUpdate(customUserDetailsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authApiUserUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VerifyEmailRequest} verifyEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authApiVerifyEmailCreate(verifyEmailRequest: VerifyEmailRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestAuthDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authApiVerifyEmailCreate(verifyEmailRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authApiVerifyEmailCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * class used for social account linking  example usage for facebook with access_token ------------- from allauth.socialaccount.providers.facebook.views import FacebookOAuth2Adapter  class FacebookConnect(SocialConnectView):     adapter_class = FacebookOAuth2Adapter -------------
         * @param {AuthApiAuthApiGoogleConnectCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiGoogleConnectCreate(requestParameters: AuthApiAuthApiGoogleConnectCreateRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<SocialConnect> {
            return localVarFp.authApiGoogleConnectCreate(requestParameters.socialConnectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * class used for social authentications example usage for facebook with access_token ------------- from allauth.socialaccount.providers.facebook.views import FacebookOAuth2Adapter  class FacebookLogin(SocialLoginView):     adapter_class = FacebookOAuth2Adapter -------------  example usage for facebook with code  ------------- from allauth.socialaccount.providers.facebook.views import FacebookOAuth2Adapter from allauth.socialaccount.providers.oauth2.client import OAuth2Client  class FacebookLogin(SocialLoginView):     adapter_class = FacebookOAuth2Adapter     client_class = OAuth2Client     callback_url = \'localhost:8000\' -------------
         * @param {AuthApiAuthApiGoogleLoginCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiGoogleLoginCreate(requestParameters: AuthApiAuthApiGoogleLoginCreateRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<SocialLogin> {
            return localVarFp.authApiGoogleLoginCreate(requestParameters.socialLoginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthApiAuthApiIsUserWithEmailActiveCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiIsUserWithEmailActiveCreate(requestParameters: AuthApiAuthApiIsUserWithEmailActiveCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.authApiIsUserWithEmailActiveCreate(requestParameters.isUserWithEmailActiveRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthApiAuthApiIsUsernameAvailableCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiIsUsernameAvailableCreate(requestParameters: AuthApiAuthApiIsUsernameAvailableCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<IsUsernameAvailableResponse> {
            return localVarFp.authApiIsUsernameAvailableCreate(requestParameters.isUsernameAvailableRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Check the credentials and return the REST Token if the credentials are valid and authenticated. Calls Django Auth login method to register User ID in Django session framework  Accept the following POST parameters: username, password Return the REST Framework Token Object\'s key.
         * @param {AuthApiAuthApiLoginCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiLoginCreate(requestParameters: AuthApiAuthApiLoginCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<JWT> {
            return localVarFp.authApiLoginCreate(requestParameters.loginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Calls Django logout method and delete the Token object assigned to the current User object.  Accepts/Returns nothing.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiLogoutCreate(options?: RawAxiosRequestConfig): AxiosPromise<RestAuthDetail> {
            return localVarFp.authApiLogoutCreate(options).then((request) => request(axios, basePath));
        },
        /**
         * Calls Django Auth SetPasswordForm save method.  Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
         * @param {AuthApiAuthApiPasswordChangeCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiPasswordChangeCreate(requestParameters: AuthApiAuthApiPasswordChangeCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<RestAuthDetail> {
            return localVarFp.authApiPasswordChangeCreate(requestParameters.passwordChangeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Password reset e-mail link is confirmed, therefore this resets the user\'s password.  Accepts the following POST parameters: token, uid,     new_password1, new_password2 Returns the success/fail message.
         * @param {AuthApiAuthApiPasswordResetConfirmCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiPasswordResetConfirmCreate(requestParameters: AuthApiAuthApiPasswordResetConfirmCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<RestAuthDetail> {
            return localVarFp.authApiPasswordResetConfirmCreate(requestParameters.passwordResetConfirmRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Calls Django Auth PasswordResetForm save method.  Accepts the following POST parameters: email Returns the success/fail message.
         * @param {AuthApiAuthApiPasswordResetCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiPasswordResetCreate(requestParameters: AuthApiAuthApiPasswordResetCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<RestAuthDetail> {
            return localVarFp.authApiPasswordResetCreate(requestParameters.passwordResetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthApiAuthApiRegisterCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiRegisterCreate(requestParameters: AuthApiAuthApiRegisterCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<RestAuthDetail> {
            return localVarFp.authApiRegisterCreate(requestParameters.customRegisterRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthApiAuthApiResendEmailVerificationCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiResendEmailVerificationCreate(requestParameters: AuthApiAuthApiResendEmailVerificationCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<RestAuthDetail> {
            return localVarFp.authApiResendEmailVerificationCreate(requestParameters.resendEmailVerificationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * List SocialAccounts for the currently logged in user
         * @param {AuthApiAuthApiSocialAccountsListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiSocialAccountsList(requestParameters: AuthApiAuthApiSocialAccountsListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedSocialAccountList> {
            return localVarFp.authApiSocialAccountsList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Disconnect SocialAccount from remote service for the currently logged in user
         * @param {AuthApiAuthApiSocialDisconnectCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiSocialDisconnectCreate(requestParameters: AuthApiAuthApiSocialDisconnectCreateRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<SocialConnect> {
            return localVarFp.authApiSocialDisconnectCreate(requestParameters.socialConnectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
         * @param {AuthApiAuthApiTokenRefreshCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiTokenRefreshCreate(requestParameters: AuthApiAuthApiTokenRefreshCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<TokenRefresh> {
            return localVarFp.authApiTokenRefreshCreate(requestParameters.tokenRefreshRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Takes a token and indicates if it is valid.  This view provides no information about a token\'s fitness for a particular use.
         * @param {AuthApiAuthApiTokenVerifyCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiTokenVerifyCreate(requestParameters: AuthApiAuthApiTokenVerifyCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.authApiTokenVerifyCreate(requestParameters.tokenVerifyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
         * @param {AuthApiAuthApiUserPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiUserPartialUpdate(requestParameters: AuthApiAuthApiUserPartialUpdateRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CustomUserDetails> {
            return localVarFp.authApiUserPartialUpdate(requestParameters.patchedCustomUserDetailsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiUserRetrieve(options?: RawAxiosRequestConfig): AxiosPromise<CustomUserDetails> {
            return localVarFp.authApiUserRetrieve(options).then((request) => request(axios, basePath));
        },
        /**
         * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
         * @param {AuthApiAuthApiUserUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiUserUpdate(requestParameters: AuthApiAuthApiUserUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<CustomUserDetails> {
            return localVarFp.authApiUserUpdate(requestParameters.customUserDetailsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthApiAuthApiVerifyEmailCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiVerifyEmailCreate(requestParameters: AuthApiAuthApiVerifyEmailCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<RestAuthDetail> {
            return localVarFp.authApiVerifyEmailCreate(requestParameters.verifyEmailRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for authApiGoogleConnectCreate operation in AuthApi.
 * @export
 * @interface AuthApiAuthApiGoogleConnectCreateRequest
 */
export interface AuthApiAuthApiGoogleConnectCreateRequest {
    /**
     * 
     * @type {SocialConnectRequest}
     * @memberof AuthApiAuthApiGoogleConnectCreate
     */
    readonly socialConnectRequest?: SocialConnectRequest
}

/**
 * Request parameters for authApiGoogleLoginCreate operation in AuthApi.
 * @export
 * @interface AuthApiAuthApiGoogleLoginCreateRequest
 */
export interface AuthApiAuthApiGoogleLoginCreateRequest {
    /**
     * 
     * @type {SocialLoginRequest}
     * @memberof AuthApiAuthApiGoogleLoginCreate
     */
    readonly socialLoginRequest?: SocialLoginRequest
}

/**
 * Request parameters for authApiIsUserWithEmailActiveCreate operation in AuthApi.
 * @export
 * @interface AuthApiAuthApiIsUserWithEmailActiveCreateRequest
 */
export interface AuthApiAuthApiIsUserWithEmailActiveCreateRequest {
    /**
     * 
     * @type {IsUserWithEmailActiveRequest}
     * @memberof AuthApiAuthApiIsUserWithEmailActiveCreate
     */
    readonly isUserWithEmailActiveRequest: IsUserWithEmailActiveRequest
}

/**
 * Request parameters for authApiIsUsernameAvailableCreate operation in AuthApi.
 * @export
 * @interface AuthApiAuthApiIsUsernameAvailableCreateRequest
 */
export interface AuthApiAuthApiIsUsernameAvailableCreateRequest {
    /**
     * 
     * @type {IsUsernameAvailableRequest}
     * @memberof AuthApiAuthApiIsUsernameAvailableCreate
     */
    readonly isUsernameAvailableRequest: IsUsernameAvailableRequest
}

/**
 * Request parameters for authApiLoginCreate operation in AuthApi.
 * @export
 * @interface AuthApiAuthApiLoginCreateRequest
 */
export interface AuthApiAuthApiLoginCreateRequest {
    /**
     * 
     * @type {LoginRequest}
     * @memberof AuthApiAuthApiLoginCreate
     */
    readonly loginRequest: LoginRequest
}

/**
 * Request parameters for authApiPasswordChangeCreate operation in AuthApi.
 * @export
 * @interface AuthApiAuthApiPasswordChangeCreateRequest
 */
export interface AuthApiAuthApiPasswordChangeCreateRequest {
    /**
     * 
     * @type {PasswordChangeRequest}
     * @memberof AuthApiAuthApiPasswordChangeCreate
     */
    readonly passwordChangeRequest: PasswordChangeRequest
}

/**
 * Request parameters for authApiPasswordResetConfirmCreate operation in AuthApi.
 * @export
 * @interface AuthApiAuthApiPasswordResetConfirmCreateRequest
 */
export interface AuthApiAuthApiPasswordResetConfirmCreateRequest {
    /**
     * 
     * @type {PasswordResetConfirmRequest}
     * @memberof AuthApiAuthApiPasswordResetConfirmCreate
     */
    readonly passwordResetConfirmRequest: PasswordResetConfirmRequest
}

/**
 * Request parameters for authApiPasswordResetCreate operation in AuthApi.
 * @export
 * @interface AuthApiAuthApiPasswordResetCreateRequest
 */
export interface AuthApiAuthApiPasswordResetCreateRequest {
    /**
     * 
     * @type {PasswordResetRequest}
     * @memberof AuthApiAuthApiPasswordResetCreate
     */
    readonly passwordResetRequest: PasswordResetRequest
}

/**
 * Request parameters for authApiRegisterCreate operation in AuthApi.
 * @export
 * @interface AuthApiAuthApiRegisterCreateRequest
 */
export interface AuthApiAuthApiRegisterCreateRequest {
    /**
     * 
     * @type {CustomRegisterRequest}
     * @memberof AuthApiAuthApiRegisterCreate
     */
    readonly customRegisterRequest: CustomRegisterRequest
}

/**
 * Request parameters for authApiResendEmailVerificationCreate operation in AuthApi.
 * @export
 * @interface AuthApiAuthApiResendEmailVerificationCreateRequest
 */
export interface AuthApiAuthApiResendEmailVerificationCreateRequest {
    /**
     * 
     * @type {ResendEmailVerificationRequest}
     * @memberof AuthApiAuthApiResendEmailVerificationCreate
     */
    readonly resendEmailVerificationRequest: ResendEmailVerificationRequest
}

/**
 * Request parameters for authApiSocialAccountsList operation in AuthApi.
 * @export
 * @interface AuthApiAuthApiSocialAccountsListRequest
 */
export interface AuthApiAuthApiSocialAccountsListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof AuthApiAuthApiSocialAccountsList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof AuthApiAuthApiSocialAccountsList
     */
    readonly offset?: number
}

/**
 * Request parameters for authApiSocialDisconnectCreate operation in AuthApi.
 * @export
 * @interface AuthApiAuthApiSocialDisconnectCreateRequest
 */
export interface AuthApiAuthApiSocialDisconnectCreateRequest {
    /**
     * 
     * @type {SocialConnectRequest}
     * @memberof AuthApiAuthApiSocialDisconnectCreate
     */
    readonly socialConnectRequest?: SocialConnectRequest
}

/**
 * Request parameters for authApiTokenRefreshCreate operation in AuthApi.
 * @export
 * @interface AuthApiAuthApiTokenRefreshCreateRequest
 */
export interface AuthApiAuthApiTokenRefreshCreateRequest {
    /**
     * 
     * @type {TokenRefreshRequest}
     * @memberof AuthApiAuthApiTokenRefreshCreate
     */
    readonly tokenRefreshRequest: TokenRefreshRequest
}

/**
 * Request parameters for authApiTokenVerifyCreate operation in AuthApi.
 * @export
 * @interface AuthApiAuthApiTokenVerifyCreateRequest
 */
export interface AuthApiAuthApiTokenVerifyCreateRequest {
    /**
     * 
     * @type {TokenVerifyRequest}
     * @memberof AuthApiAuthApiTokenVerifyCreate
     */
    readonly tokenVerifyRequest: TokenVerifyRequest
}

/**
 * Request parameters for authApiUserPartialUpdate operation in AuthApi.
 * @export
 * @interface AuthApiAuthApiUserPartialUpdateRequest
 */
export interface AuthApiAuthApiUserPartialUpdateRequest {
    /**
     * 
     * @type {PatchedCustomUserDetailsRequest}
     * @memberof AuthApiAuthApiUserPartialUpdate
     */
    readonly patchedCustomUserDetailsRequest?: PatchedCustomUserDetailsRequest
}

/**
 * Request parameters for authApiUserUpdate operation in AuthApi.
 * @export
 * @interface AuthApiAuthApiUserUpdateRequest
 */
export interface AuthApiAuthApiUserUpdateRequest {
    /**
     * 
     * @type {CustomUserDetailsRequest}
     * @memberof AuthApiAuthApiUserUpdate
     */
    readonly customUserDetailsRequest: CustomUserDetailsRequest
}

/**
 * Request parameters for authApiVerifyEmailCreate operation in AuthApi.
 * @export
 * @interface AuthApiAuthApiVerifyEmailCreateRequest
 */
export interface AuthApiAuthApiVerifyEmailCreateRequest {
    /**
     * 
     * @type {VerifyEmailRequest}
     * @memberof AuthApiAuthApiVerifyEmailCreate
     */
    readonly verifyEmailRequest: VerifyEmailRequest
}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * class used for social account linking  example usage for facebook with access_token ------------- from allauth.socialaccount.providers.facebook.views import FacebookOAuth2Adapter  class FacebookConnect(SocialConnectView):     adapter_class = FacebookOAuth2Adapter -------------
     * @param {AuthApiAuthApiGoogleConnectCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authApiGoogleConnectCreate(requestParameters: AuthApiAuthApiGoogleConnectCreateRequest = {}, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authApiGoogleConnectCreate(requestParameters.socialConnectRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * class used for social authentications example usage for facebook with access_token ------------- from allauth.socialaccount.providers.facebook.views import FacebookOAuth2Adapter  class FacebookLogin(SocialLoginView):     adapter_class = FacebookOAuth2Adapter -------------  example usage for facebook with code  ------------- from allauth.socialaccount.providers.facebook.views import FacebookOAuth2Adapter from allauth.socialaccount.providers.oauth2.client import OAuth2Client  class FacebookLogin(SocialLoginView):     adapter_class = FacebookOAuth2Adapter     client_class = OAuth2Client     callback_url = \'localhost:8000\' -------------
     * @param {AuthApiAuthApiGoogleLoginCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authApiGoogleLoginCreate(requestParameters: AuthApiAuthApiGoogleLoginCreateRequest = {}, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authApiGoogleLoginCreate(requestParameters.socialLoginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthApiAuthApiIsUserWithEmailActiveCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authApiIsUserWithEmailActiveCreate(requestParameters: AuthApiAuthApiIsUserWithEmailActiveCreateRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authApiIsUserWithEmailActiveCreate(requestParameters.isUserWithEmailActiveRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthApiAuthApiIsUsernameAvailableCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authApiIsUsernameAvailableCreate(requestParameters: AuthApiAuthApiIsUsernameAvailableCreateRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authApiIsUsernameAvailableCreate(requestParameters.isUsernameAvailableRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Check the credentials and return the REST Token if the credentials are valid and authenticated. Calls Django Auth login method to register User ID in Django session framework  Accept the following POST parameters: username, password Return the REST Framework Token Object\'s key.
     * @param {AuthApiAuthApiLoginCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authApiLoginCreate(requestParameters: AuthApiAuthApiLoginCreateRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authApiLoginCreate(requestParameters.loginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Calls Django logout method and delete the Token object assigned to the current User object.  Accepts/Returns nothing.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authApiLogoutCreate(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authApiLogoutCreate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Calls Django Auth SetPasswordForm save method.  Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
     * @param {AuthApiAuthApiPasswordChangeCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authApiPasswordChangeCreate(requestParameters: AuthApiAuthApiPasswordChangeCreateRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authApiPasswordChangeCreate(requestParameters.passwordChangeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Password reset e-mail link is confirmed, therefore this resets the user\'s password.  Accepts the following POST parameters: token, uid,     new_password1, new_password2 Returns the success/fail message.
     * @param {AuthApiAuthApiPasswordResetConfirmCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authApiPasswordResetConfirmCreate(requestParameters: AuthApiAuthApiPasswordResetConfirmCreateRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authApiPasswordResetConfirmCreate(requestParameters.passwordResetConfirmRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Calls Django Auth PasswordResetForm save method.  Accepts the following POST parameters: email Returns the success/fail message.
     * @param {AuthApiAuthApiPasswordResetCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authApiPasswordResetCreate(requestParameters: AuthApiAuthApiPasswordResetCreateRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authApiPasswordResetCreate(requestParameters.passwordResetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthApiAuthApiRegisterCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authApiRegisterCreate(requestParameters: AuthApiAuthApiRegisterCreateRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authApiRegisterCreate(requestParameters.customRegisterRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthApiAuthApiResendEmailVerificationCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authApiResendEmailVerificationCreate(requestParameters: AuthApiAuthApiResendEmailVerificationCreateRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authApiResendEmailVerificationCreate(requestParameters.resendEmailVerificationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List SocialAccounts for the currently logged in user
     * @param {AuthApiAuthApiSocialAccountsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authApiSocialAccountsList(requestParameters: AuthApiAuthApiSocialAccountsListRequest = {}, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authApiSocialAccountsList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Disconnect SocialAccount from remote service for the currently logged in user
     * @param {AuthApiAuthApiSocialDisconnectCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authApiSocialDisconnectCreate(requestParameters: AuthApiAuthApiSocialDisconnectCreateRequest = {}, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authApiSocialDisconnectCreate(requestParameters.socialConnectRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
     * @param {AuthApiAuthApiTokenRefreshCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authApiTokenRefreshCreate(requestParameters: AuthApiAuthApiTokenRefreshCreateRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authApiTokenRefreshCreate(requestParameters.tokenRefreshRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Takes a token and indicates if it is valid.  This view provides no information about a token\'s fitness for a particular use.
     * @param {AuthApiAuthApiTokenVerifyCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authApiTokenVerifyCreate(requestParameters: AuthApiAuthApiTokenVerifyCreateRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authApiTokenVerifyCreate(requestParameters.tokenVerifyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     * @param {AuthApiAuthApiUserPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authApiUserPartialUpdate(requestParameters: AuthApiAuthApiUserPartialUpdateRequest = {}, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authApiUserPartialUpdate(requestParameters.patchedCustomUserDetailsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authApiUserRetrieve(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authApiUserRetrieve(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     * @param {AuthApiAuthApiUserUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authApiUserUpdate(requestParameters: AuthApiAuthApiUserUpdateRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authApiUserUpdate(requestParameters.customUserDetailsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthApiAuthApiVerifyEmailCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authApiVerifyEmailCreate(requestParameters: AuthApiAuthApiVerifyEmailCreateRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authApiVerifyEmailCreate(requestParameters.verifyEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

