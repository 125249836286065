/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const AnsweredCorrectlyStatusEnum = {
    AnsweredCorrectly: 'answered-correctly',
    NotAnsweredCorrectly: 'not-answered-correctly',
    PartiallyAnsweredCorrectly: 'partially-answered-correctly'
} as const;

export type AnsweredCorrectlyStatusEnum = typeof AnsweredCorrectlyStatusEnum[keyof typeof AnsweredCorrectlyStatusEnum];



